import React from 'react';
import {partner as partnerContent} from '../../../../../helpers/content';
import {useWindowSize} from '../../../../../hooks/use-window-size';
import {SlideContent} from '../../../home/sections/intro/slide-content';
import './intro.css';

export const Intro = props => {
  // PROPS
  const {name} = props;

  // USE WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // CONTENT
  const content = partnerContent[name].intro;

  // BACKGROUND IMAGE
  let backgroundImage = content.defaultBackgroundImagePath;
  if (windowSize.width <= 500) {
    backgroundImage = content.smallBackgroundImagePath;
  }

  // CLASS NAMES
  const introMainClassName = `position--${content.position}`;

  // is silent-gliss
  const isSilentGliss = content.silentGlissBodhiIdentifier;

  // RENDER
  return (
    <div className="intro">
      <div
        className={`intro-main ${introMainClassName}`}
        style={{
          backgroundImage: isSilentGliss
            ? `linear-gradient(to right, #d0a37c, transparent), url(${backgroundImage})`
            : `url(${backgroundImage})`,
        }}
      >
        {/* DEVELOPER NOTE: Slide Content is coming from the home page intros for consistency across pages */}
        <SlideContent
          carouselSlide={content}
          rowHeading={content.rowHeading}
          columnHeading={content.columnHeading}
          hasButton={content.hasButton}
          increaseLogoSize={content.increaseLogoSize}
          fontColor={content.color}
        />
      </div>
    </div>
  );
};
