import React, {Fragment} from 'react';
import {Footer, NavigationBar, Request} from '../../sections';
import {Intro, Examples, CaseStudies} from './sections';

export const Solution = props => {
  const {name = 'crestron', navigationBarProps} = props;

  return (
    <div className="solution">
      <NavigationBar {...navigationBarProps} /> 
      <Intro name={name} />
      <Examples name={name} />
      <CaseStudies name={name} />
      <Footer />
    </div>
  );
};
