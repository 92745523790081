import React from 'react';
import {partner as partnerContent} from '../../../../../helpers/content';
import {Paragraph} from '../../../../ui';
import './illustrations.css';

export const Illustrations = props => {
  // PROPS
  const {name} = props;

  // CONTENT
  const content = partnerContent[name].illustrations;
  if (!content?.items?.length) return null;

  // RENDER
  return (
    <div className="illustrations">
      <div className="illustrations-main">
        {content.items.map((item, index) => (
          <div className="illustrations-main-item">
            <img
              alt={item.imageAlternateText}
              className="illustrations-main-item-image"
              src={item.imagePath}
            />
            <div className="illustrations-main-item-paragraph">
              <Paragraph lineHeight="1.5em" size={4} textAlign="inherit">
                <b>{item.descriptionBoldedText}</b>
                {item.descriptionRegularText}
              </Paragraph>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
