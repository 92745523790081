import React, {Fragment} from 'react';
import {Footer, NavigationBar, Request} from '../../sections';
import {termsOfService as termsOfServiceContent} from '../../../helpers/content';
import termsOfServiceStyles from './terms-of-service.styles.module.css';

export const TermsOfService = props => {
  console.log('[remove me] termsOfServiceContent', termsOfServiceContent);
  console.log('[remove me] termsOfServiceStyles', termsOfServiceStyles);

  // handle rendering
  return (
    <Fragment>
      <NavigationBar />
      <div className={termsOfServiceStyles.content}>
        <div className={termsOfServiceStyles.contentMain}>
          {termsOfServiceContent.items.map((item, itemIndex) => {
            // handle rendering body items
            if (item.type === 'body') {
              return (
                <div
                  className={`${termsOfServiceStyles.contentItem} ${termsOfServiceStyles.contentBody}`}
                  key={itemIndex}
                >
                  {item.value}
                </div>
              );
            }

            // handle rendering heading items
            if (item.type === 'heading') {
              return (
                <div
                  className={`${termsOfServiceStyles.contentItem} ${termsOfServiceStyles.contentHeading}`}
                  key={itemIndex}
                >
                  {item.value}
                </div>
              );
            }

            // handle rendering numbered list items
            if (item.type === 'numbered-list') {
              return (
                <ol
                  className={`${termsOfServiceStyles.contentItem} ${termsOfServiceStyles.contentList}`}
                  key={itemIndex}
                  type="1"
                >
                  {item.value.items.map((listItem, listItemIndex) => {
                    return (
                      <li className={`${termsOfServiceStyles.contentListItem}`} key={listItemIndex}>
                        {listItem.value}
                      </li>
                    );
                  })}
                </ol>
              );
            }

            // handle rendering alphabetical list items
            if (item.type === 'alphabetical-list') {
              return (
                <ol
                  className={`${termsOfServiceStyles.contentItem} ${termsOfServiceStyles.contentList}`}
                  key={itemIndex}
                  type="a"
                >
                  {item.value.items.map((listItem, listItemIndex) => {
                    return (
                      <li className={`${termsOfServiceStyles.contentListItem}`} key={listItemIndex}>
                        {listItem.value}
                      </li>
                    );
                  })}
                </ol>
              );
            }
          })}
        </div>
      </div>
      <Request />
      <Footer />
    </Fragment>
  );
};
