import React from 'react';
import {Footer, NavigationBar} from '../../sections';
import {Intro} from './sections';

export const Contact = () => {
  return (
    <div className="contact">
      <NavigationBar />
      <Intro />
      <Footer />
    </div>
  );
};
