import React, {useMemo} from 'react';
import {navigate} from 'gatsby';
import {Heading, Paragraph} from '../../../../../ui';
import {useWindowSize} from '../../../../../../hooks/use-window-size';
import './slide-content.css';

export const SlideContent = ({
  carouselSlide,
  fontColor = '#000',
  hasButton = true,
  rowHeading = false,
  columnHeading = false,
  increaseLogoSize = false,
  isHomePage = false,
}) => {
  // hooks
  const {width: windowSizeWidth} = useWindowSize();

  // memo variables
  const plusIconMemo = useMemo(() => {
    if (windowSizeWidth <= 500) {
      return 'small';
    } else if (windowSizeWidth < 801 && windowSizeWidth >= 501) {
      return 'large';
    } else {
      return 'big';
    }
  }, [windowSizeWidth]);

  const crestronLogoMemo = useMemo(() => {
    if (windowSizeWidth <= 500) {
      return '16.5px';
    } else if (windowSizeWidth < 801 && windowSizeWidth >= 501) {
      return '21.5px';
    } else if (windowSizeWidth > 1200) {
      return '32.5px';
    } else {
      return '27.5px';
    }
  }, [windowSizeWidth]);

  const increasedLogoMemo = useMemo(() => {
    if (windowSizeWidth <= 500) {
      return '31.5px';
    } else if (windowSizeWidth < 801 && windowSizeWidth >= 501) {
      return '36.5px';
    } else if (windowSizeWidth > 1200) {
      return '57.5px';
    } else {
      return '47.5px';
    }
  }, [windowSizeWidth]);

  const crestronHomeLogoMemo = useMemo(() => {
    if (windowSizeWidth <= 500) {
      return '7.75rem';
    } else if (windowSizeWidth < 801 && windowSizeWidth >= 501) {
      return '8.5rem';
    } else if (windowSizeWidth > 1200) {
      return '11.5rem';
    } else {
      return '10rem';
    }
  }, [windowSizeWidth]);

  // for paragraph text on the bodhi-sensors slide on partners page
  const bodhiSensorsPartnerTextStyles =
    !!carouselSlide.bodhiSensorsIdentifier && !!carouselSlide.partnerPage
      ? {position: 'relative', bottom: '1.475rem'}
      : {};

  // For the paragraph and button of the crestron-home slide on home page
  const crestronHomeIntroTextStyles = !!carouselSlide.crestronHomeBodhiIdentifier
    ? {
        position: 'relative',
        bottom: windowSizeWidth > 800 ? '4.15rem' : '3.5rem',
        paddingBottom: '1rem',
      }
    : {};

  const crestronHomeButtonStyles = !!carouselSlide.crestronHomeBodhiIdentifier
    ? {position: 'relative', bottom: windowSizeWidth > 800 ? '3rem' : '3.25rem'}
    : {};

  // Render mobile title for building operation slides
  const renderTitleText = () => {
    if (!!carouselSlide.buildingManagementIdentifier && windowSizeWidth < 500) {
      return carouselSlide.mobileTitle;
    } else {
      return carouselSlide.title;
    }
  };

  // render component with conditional class names
  return (
    <div
      className={`slide-content__container ${
        !!carouselSlide.bodhiSensorsIdentifier && 'bodhi-sensors'
      } ${!!carouselSlide.rakoBodhiIdentifier && 'rako'} ${
        !!carouselSlide.crestronHomeBodhiIdentifier && 'crestron-home'
      } ${!!carouselSlide.crestronBodhiCloudIdentifier && 'crestron-cloud'} ${
        !!carouselSlide.buildingManagementIdentifier && 'building-management'
      } ${!!carouselSlide.lutronBodhiIdentifier && 'lutron-bodhi'} ${
        !!carouselSlide.silentGlissBodhiIdentifier && 'silent-gliss'
      } ${isHomePage ? 'home-page' : ''}`}
    >
      {/* Regular text heading */}
      {!rowHeading && !columnHeading && (
        <div
          className={`slide-content__heading-container _effect`}
          style={{marginBottom: windowSizeWidth < 901 ? '1rem' : '2.5rem'}}
        >
          <h6 className={'slide-content__heading'} style={{color: fontColor}}>
            {renderTitleText()}
          </h6>
        </div>
      )}

      {/* for heading text and image in a flex row */}
      {rowHeading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginBottom:
              windowSizeWidth < 500 ? '1rem' : windowSizeWidth < 801 ? '1.25rem' : '2.25rem',
          }}
        >
          <img
            src={carouselSlide.logoImagePath}
            style={{height: increaseLogoSize ? increasedLogoMemo : crestronLogoMemo}}
          />
          <i
            class={`plus icon ${plusIconMemo}`}
            style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}
          ></i>

          <span
            className={'slide-content__heading-row'}
            style={{
              fontWeight: '700',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              left: '2px',
            }}
          >
            {carouselSlide.title !== null ? (
              carouselSlide.title
            ) : (
              <img src={carouselSlide.titleAsImage} style={{maxHeight: '3.75rem'}} />
            )}
          </span>
        </div>
      )}

      {/* for header text and image in a flex column */}
      {columnHeading && (
        <div
          className={'_effect'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom:
              windowSizeWidth < 500 ? '1.5rem' : windowSizeWidth < 801 ? '1.6rem' : '2rem',
          }}
        >
          <h6
            className={'slide-content__heading-row'}
            style={{marginBottom: '0', fontWeight: '700'}}
          >
            {carouselSlide.title}
          </h6>

          <img
            src={carouselSlide.logoImagePath}
            style={{
              height: crestronHomeLogoMemo,
              position: 'relative',
              bottom: windowSizeWidth > 800 ? '2.5rem' : '1.5rem',
              right: windowSizeWidth > 800 ? '1rem' : '0.7rem',
            }}
          />
        </div>
      )}

      {/* description paragraph */}
      <div
        className={`slide-content__paragraph`}
        style={{
          color: fontColor,
          fontWeight: 300,
          paddingBottom:
            windowSizeWidth < 500 ? '1.4rem' : windowSizeWidth < 801 ? '1.5rem' : '3rem',
          ...bodhiSensorsPartnerTextStyles,
          ...crestronHomeIntroTextStyles,
        }}
      >
        <span style={{fontWeight: '700', lineHeight: '1.45rem'}}>
          {carouselSlide.boldDescription}
        </span>{' '}
        {carouselSlide.description}
      </div>

      {/* button */}
      {/* {hasButton && ( */}
      <button
        onClick={() => navigate(carouselSlide.path)}
        class={fontColor === '#fff' ? 'ui inverted button' : 'ui secondary basic button'}
        id={!!carouselSlide.bodhiSensorsIdentifier ? 'learn-more-white' : 'learn-more-btn'}
        style={{
          ...crestronHomeButtonStyles,
          visibility: hasButton ? 'visible' : 'hidden',
        }}
      >
        {carouselSlide.buttonText}
      </button>
      {/* )} */}
    </div>
  );
};
