import React, {Fragment} from 'react';
import {Footer, NavigationBar, Request} from '../../sections';
import {Description, Examples, Gallery, Illustrations, Intro} from './sections';

export const Partner = props => {
  const {name = 'crestron'} = props;

  return (
    <div className="partner">
      <NavigationBar />
      <Intro name={name} />
      <Description name={name} />
      <Gallery name={name} />
      <Illustrations name={name} />
      <Examples name={name} /> 
      <Request />
      <Footer />
    </div>
  );
};
