import React, {useState, useEffect} from 'react';
import {home as homeContent} from '../../../../../helpers/content';
import {useWindowSize} from '../../../../../hooks';
import {Heading, Icon, Modal, Paragraph} from '../../../../ui';
import './meet.css';

export const Meet = () => {
  // STATE HOOK
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [carouselSelectedItem, setCarouselSelectedItem] = useState(0);
  const [carouselShowArrows, setCarouselShowArrows] = useState(false);
  const [carouselShowIndicators, setCarouselShowIndicators] = useState(false);

  // WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // USE EFFECT HOOK
  useEffect(() => {
    // SHOW CAROUSEL CONTROLS ON SMALLER SCREEN SIZES
    if (windowSize.width <= 1100) {
      setCarouselShowArrows(true);
      setCarouselShowIndicators(true);
    } else {
      setCarouselShowArrows(false);
      setCarouselShowIndicators(false);
    }
  });

  // EVENT HANDLERS
  const onWatchVideoClick = () => {
    setModalIsVisible(true);
  };

  const onModalBackgroundClick = () => {
    setModalIsVisible(false);
  };

  // RENDER
  return (
    <div className="features">
      <div className="meet-main">
        {/* TITLE */}
        <div className="meet-main-heading">
          <Heading color="inherit" size={3} importance={2} textAlign="center">
            {homeContent.meet.title}
          </Heading>
        </div>

        {/* DESCRIPTION */}
        {homeContent.meet.description.map((paragraph, index) => (
          <div className="meet-main-paragraph" key={index}>
            <Paragraph color="inherit" lineHeight={'inherit'} size={4} textAlign="center">
              {paragraph}
            </Paragraph>
          </div>
        ))}

        {/* VIDEO BUTTON */}
        <button className="meet-main-button" onClick={onWatchVideoClick}>
          <span className="meet-main-button-text">{homeContent.meet.buttonText}</span>
          <span className="meet-main-button-icon">
            <Icon color="#ffffff" name="play-solid" size="100%" />
          </span>
        </button>

        {/* VIDEO MODAL */}
        <Modal isVisible={modalIsVisible} backgroundOnClick={onModalBackgroundClick}>
          <iframe
            src="https://player.vimeo.com/video/459042131?autoplay=1"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen={true}
            className="meet-main-modal-iFrame"
          />
        </Modal>
      </div>
    </div>
  );
};
