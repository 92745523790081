import React from 'react';
import {partner as partnerContent} from '../../../../../helpers/content';
import {Paragraph} from '../../../../ui';
import './description.css';

export const Description = props => {
  // PROPS
  const {name} = props;

  // CONTENT
  const content = partnerContent[name].description;

  // RENDER
  return (
    <div className="description">
      <div className="description-main">
        <div className="description-main-paragraph">
          <Paragraph color="#ffffff" lineHeight="1.5em" size={4} textAlign="center">
            {content.value}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
