import React, {useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {partner, partner as partnerContent} from '../../../../../helpers/content';
import {Heading, Paragraph} from '../../../../ui';
import './gallery.css';

export const Gallery = props => {
  // PROPS
  const {name} = props;

  // STATE
  const [selectedItem, setSelectedItem] = useState(0);

  // CONTENT
  const content = partnerContent[name].gallery;

  // EVENT HANDLERS
  const onCarouselChange = index => {
    setSelectedItem(index);
  };

  const onControlItemClick = index => {
    setSelectedItem(index);
  };

  const onControlItemFocus = index => {
    setSelectedItem(index);
  };

  // check for silent gliss images
  const isSilentGliss = partnerContent[name].intro.silentGlissBodhiIdentifier;

  // RENDER
  return (
    <div className="gallery">
      <div className="gallery-main">
        {/* CAROUSEL, ONLY SHOWS ON LARGE DEVICES */}
        <Carousel
          autoPlay={true}
          className="gallery-main-carousel"
          infiniteLoop={true}
          interval={16000}
          selectedItem={selectedItem}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          onChange={onCarouselChange}
        >
          {content.items.map((item, index) => (
            <div
              className={`gallery-main-carousel-slide ${isSilentGliss ? 'silent-gliss' : ''}`}
              key={index}
            >
              <img
                className="gallery-main-carousel-slide-image"
                src={item.imagePath}
                alt={item.imageAlternateText}
              />
              <div className="gallery-main-carousel-slide-main">
                <div className="gallery-main-carousel-slide-main-heading">
                  <Heading
                    color="#ffffff"
                    importance={2}
                    textAlign="left"
                    lineHeight="1.5em"
                    size={3}
                  >
                    {item.title}
                  </Heading>
                </div>
                <div className="gallery-main-carousel-slide-main-paragraph">
                  <Paragraph
                    color="#ffffff"
                    importance={2}
                    textAlign="left"
                    lineHeight="1.5em"
                    size={4}
                  >
                    {item.description}
                  </Paragraph>
                </div>
              </div>
            </div>
          ))}
        </Carousel>

        {/* CONTROLS, ONLY SHOWS ON LARGE DEVICES */}
        <div className="gallery-main-controls">
          {content.items.map((item, index) => {
            // CLASS NAMES

            let galleryMainControlsItemIsSelected = index === selectedItem;

            let galleryMainControlsItemClassName = `gallery-main-controls-item is-selected--${galleryMainControlsItemIsSelected}`;

            return (
              <div
                className={galleryMainControlsItemClassName}
                key={index}
                tabIndex={0}
                onClick={() => onControlItemClick(index)}
                onFocus={() => onControlItemFocus(index)}
              >
                {item.controlName}
              </div>
            );
          })}
        </div>

        {/* CARDS, ONLY SHOWS ON MEDIUM AND SMALL DEVICES */}
        <div className="gallery-main-cards">
          {content.items.map((item, index) => (
            <div className="gallery-main-cards-item" key={index}>
              <img className="gallery-main-cards-item-image" src={item.imagePath} />
              <div className="gallery-main-cards-item-main">
                <div className="gallery-main-cards-item-main-heading">
                  <Heading size={4} importance={3}>
                    {item.title}
                  </Heading>
                </div>
                <div className="gallery-main-cards-item-main-paragraph">
                  <Paragraph lineHeight="1.5em" size={6}>
                    {item.description}
                  </Paragraph>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
