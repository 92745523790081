import React, {Fragment, useRef, useState} from 'react';
import {Heading, Icon, Paragraph} from '../../../../ui';
import {salesforceConfiguration} from '../../../../../config/saleforce';
import './intro.css';

export const Intro = () => {
  // state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [showForm, setShowForm] = useState(true);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  // ref
  const salesforceSubmitButtonRef = useRef();

  // event handlers
  const onFieldChange = (e, property) => {
    updateFormData(property, e.target.value);
  };

  const onSendMessageButtonClick = () => {
    setShowForm(false);
    setShowThankYouMessage(true);
    sendToSalesforce();
  };

  // helper functions
  const updateFormData = (property, value) => {
    formData[property] = value;
    const newFormData = {...formData};
    setFormData(newFormData);
  };

  const sendToSalesforce = () => {
    salesforceSubmitButtonRef.current.click();
  };

  // RENDER
  return (
    <div className="intro">
      <div className="intro-main">
        {/* FORM CONTENT */}
        {showForm && (
          <Fragment>
            <div className="intro-main-heading">
              <Heading content="Get in touch" lineHeight="1.5em" size={3} textAlign="center" />
            </div>
            <div className="intro-main-paragraph">
              <Paragraph
                content="Thanks for your interest in Bodhi! Have a question, issue or an idea? Please send us a quick message. We look forward to hearing from you."
                lineHeight="1.5em"
                size={4}
                textAlign="center"
              />
            </div>
            <form className="intro-main-form">
              <input
                className="intro-main-form-input"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={e => onFieldChange(e, 'name')}
              />
              <input
                className="intro-main-form-input"
                name="Email"
                placeholder="Email"
                value={formData.email}
                onChange={e => onFieldChange(e, 'email')}
              />
              <input
                className="intro-main-form-input"
                name="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={e => onFieldChange(e, 'phoneNumber')}
              />
              <textarea
                rows={4}
                className="intro-main-form-textarea"
                name="phoneNumber"
                placeholder="Message"
                value={formData.message}
                onChange={e => onFieldChange(e, 'message')}
              />
            </form>
            <button className="intro-main-button" onClick={onSendMessageButtonClick}>
              SEND MESSAGE
            </button>
          </Fragment>
        )}

        {/* SALESFORCE FORM */}
        <form
          className="request-hidden"
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
        >
          <input type="hidden" name="oid" value={salesforceConfiguration.webToLeadOid} />

          <input type="hidden" name="retURL" value={salesforceConfiguration.webToLeadReturnURL} />

          {/* FIRST NAME */}
          <input
            id="first_name"
            maxLength="40"
            name="first_name"
            size="20"
            type="text"
            value={formData.name}
          />

          {/* LAST NAME */}
          <input id="last_name" maxLength="40" name="last_name" size="20" type="text" value="" />

          {/* COMPANY */}
          <input
            id="company"
            maxLength="40"
            name="company"
            size="20"
            type="text"
            value={formData.company}
          />

          {/* FULL NAME */}
          <input
            id="00N5e00000J2uAj"
            maxLength="40"
            name="00N5e00000J2uAj"
            size="20"
            type="text"
            value={formData.name}
          />

          {/* EMAIL */}
          <input
            id="email"
            maxLength="80"
            name="email"
            size="20"
            type="text"
            value={formData.email}
          />

          {/* PHONE */}
          <input
            id="phone"
            maxLength="80"
            name="phone"
            size="20"
            type="text"
            value={formData.phoneNumber}
          />

          {/* FORM MESSAGE */}
          <textarea
            id="00N5e00000bBYnU"
            name="00N5e00000bBYnU"
            type="text"
            value={formData.message}
            wrap="soft"
          />

          {/* FORM NAME */}
          <input
            id="00N5e00000XON2R"
            maxLength="64"
            name="00N5e00000XON2R"
            size="20"
            type="text"
            value="contact"
          />

          {/* SUBMIT BUTTON */}
          <input name="submit" ref={salesforceSubmitButtonRef} type="submit" />
        </form>

        {/* THANK YOU CONTENT */}
        {showThankYouMessage && (
          <div className="intro-main-thank-you">
            <div className="intro-main-thank-you-icon">
              <Icon name="check-solid" size="3em" />
            </div>
            <div className="intro-main-thank-you-heading">
              <Heading content="Thank you!" lineHeight="1.5em" size={3} textAlign="center" />
            </div>
            <div className="intro-main-thank-you-paragraph">
              <Paragraph
                content="We’ve received your information, and we’ll be in touch soon!"
                lineHeight="1.5em"
                size={4}
                textAlign="center"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
