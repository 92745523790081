import React, {Fragment} from 'react';
import {newPage as newContent} from '../../../../../helpers/content';
import {Heading, Paragraph} from '../../../../ui';
import './case-studies.css';

export const CaseStudies = props => {
  // PROPS
  const {name} = props;

  // CONTENT
  const content = newContent[name].caseStudies;

  // RENDER
  return (
    content.length > 0 && (
      <div className="case-studies">
        <div className="main">
          {/* single case study */}

          {content.length === 1 && (
            <Fragment>
              <div className="heading container">
                <Heading size="3">CASE STUDY</Heading>
              </div>
              <div className="subheading container">
                <Heading size="3">{content[0].value}</Heading>
              </div>
              <div className="bullet-points">
                {content[0].items.map((element, elementIndex) => {
                  return (
                    <div className="item">
                      <Paragraph size={5} lineHeight="inherit">
                        • {element}
                      </Paragraph>
                    </div>
                  );
                })}
              </div>
              <div className="image container">
                <div
                  onClick={() => {
                    window.location.assign(content[0].link);
                  }}
                  className="image"
                  style={{
                    backgroundImage: `url("${content[0].imagePath}")`,
                  }}
                ></div>
              </div>
            </Fragment>
          )}

          {/* multiple case studies */}
          {content.length > 1 && (
            <Fragment>
              <div className="heading container">
                <Heading size="3">CASE STUDIES</Heading>
              </div>
              <div className="cards">
                {/* cards */}
                {content.map((element, elementIndex) => {
                  return (
                    <div key={elementIndex} className="item">
                      <div
                        onClick={() => {
                          window.location.assign(element.link);
                        }}
                        className="image container"
                        style={{
                          backgroundImage: `url("${element.imagePath}")`,
                        }}
                      >
                        <div className="heading container">
                          <Heading size="3" textAlign="center" color="#ffffff">
                            {element.value}
                          </Heading>
                        </div>
                      </div>
                      <div className="bullet-points">
                        {/* bullet points */}
                        {element.items.map((element, elementIndex) => {
                          return (
                            <div className="item" key={elementIndex}>
                              <Paragraph size={5} lineHeight="inherit">
                                • {element}
                              </Paragraph>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  );
};
