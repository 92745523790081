import React, {useState, useEffect} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {home as homeContent} from '../../../../../helpers/content';
import {useWindowSize} from '../../../../../hooks';
import {Heading, Modal, Paragraph} from '../../../../ui';
import './core-components.css';

export const CoreComponents = () => {
  // STATE HOOK
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [carouselSelectedItem, setCarouselSelectedItem] = useState(0);
  const [carouselShowArrows, setCarouselShowArrows] = useState(false);
  const [carouselShowIndicators, setCarouselShowIndicators] = useState(false);

  // WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // USE EFFECT HOOK
  useEffect(() => {
    // SHOW CAROUSEL CONTROLS ON SMALLER SCREEN SIZES
    if (windowSize.width <= 1100) {
      setCarouselShowArrows(true);
      setCarouselShowIndicators(true);
    } else {
      setCarouselShowArrows(false);
      setCarouselShowIndicators(false);
    }
  });

  // EVENT HANDLERS
  const onWatchVideoClick = () => {
    setModalIsVisible(true);
  };

  const onModalBackgroundClick = () => {
    setModalIsVisible(false);
  };

  const onCarouselChange = index => {
    setCarouselSelectedItem(index);

    // debugging
    // console.log(`[debug] index: ${index}`);
  };

  const onControlFocus = index => {
    setCarouselSelectedItem(index);

    // debugging
    // console.log('[debug] onControlFocus called')
  };

  // RENDER
  return (
    <div className="core-components">
      <div className="core-components-main">
        {/* VIDEO MODAL */}
        <Modal isVisible={modalIsVisible} backgroundOnClick={onModalBackgroundClick}>
          <iframe
            src="https://player.vimeo.com/video/459042131?autoplay=1"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen={true}
            className="core-components-main-modal-iFrame"
          />
        </Modal>

        {/* CAROUSEL */}
        <div className="core-components-main-carousel-heading">
          <Heading color="inherit" importance={3} size={3} textAlign="center">
            {homeContent.coreComponents.title}
          </Heading>
        </div>

        {/* CONTROLS */}
        <div className="core-components-controls">
          <div className="core-components-controls-main">
            {homeContent.coreComponents.items.map((item, index) => {
              let className = 'core-components-controls-main-item';
              if (carouselSelectedItem === index) {
                className += ' core-components-controls-main-item--selected';
              }
              // To fix spacing issue caused by predictive maintenance being longer than the other items
              const predictiveMaintenanceClassName = item.value === 'Predictive Maintenance' ? 'core-components-controls-main-item--predictive-maintenance' : '';
              return (
                <div
                  className={`${className} ${predictiveMaintenanceClassName}`}
                  key={index}
                  tabIndex={0}
                  onFocus={() => onControlFocus(index)}
                >
                  {item.value}
                </div>
              );
            })}
          </div>
        </div>

        {/* CAROUSEL, ONLY SHOWS ON LARGE DEVICES */}
        <Carousel
          autoPlay={true}
          className="core-components-main-carousel"
          infiniteLoop={true}
          interval={8000}
          onChange={onCarouselChange}
          selectedItem={carouselSelectedItem}
          showArrows={carouselShowArrows}
          showIndicators={carouselShowIndicators}
          showStatus={false}
          showThumbs={false}
        >
          {homeContent.coreComponents.items.map((item, index) => (
            <div className="core-components-main-carousel-slide" key={index}>
              <div className="core-components-main-carousel-slide-image">
                <div
                  className="core-components-main-carousel-slide-image-main"
                  style={{
                    backgroundImage: `url(${item.imagePath})`,
                  }}
                />
              </div>
              <div className="core-components-main-carousel-slide-main">
                <div className="core-components-main-carousel-slide-main-heading">
                  <Heading size={3}>{item.value}</Heading>
                </div>
                <div className="core-components-main-carousel-slide-main-paragraph">
                  <Paragraph lineHeight="inherit" size={5}>
                    {item.description}
                  </Paragraph>
                </div>
                {item.bulletPoints.map((bulletPoint, index) => (
                  <div
                    className="core-components-main-carousel-slide-main-bullet-point"
                    key={index}
                  >
                    <Paragraph display="inline" size={'0'}></Paragraph>
                    {bulletPoint}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>

        {/* CARDS, ONLY SHOWS ON MEDIUM AND SMALL DEVICES */}
        <div className="core-components-main-cards">
          {homeContent.coreComponents.items.map((item, index) => (
            <div className="core-components-main-cards-item" key={index}>
              <img className="core-components-main-cards-item-image" src={item.imagePath} />
              <div className="core-components-main-cards-item-main">
                <div className="core-components-main-cards-item-main-heading">
                  <Heading size={4} importance={3}>
                    {item.value}
                  </Heading>
                </div>
                <div className="core-components-main-cards-item-main-paragraph">
                  <Paragraph lineHeight="1.5em" size={6}>
                    {item.description}
                  </Paragraph>
                </div>
                {item.bulletPoints.map((bulletPoint, index) => (
                  <div className="core-components-main-cards-item-main-bullet-point" key={index}>
                    <Paragraph display="inline" lineHeight="1.5em" size={7}>
                      {bulletPoint}
                    </Paragraph>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
