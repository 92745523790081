import React, {Fragment} from 'react';
import {Footer, NavigationBar, Request} from '../../sections';
import {CoreComponents, Meet, Intro, Projects, Partners} from './sections';

export const Home = () => {
  return (
    <div className="home">
      <NavigationBar />
      <Intro />
      <Meet />
      <CoreComponents />
      <Projects />
      <Partners />
      <Request />
      <Footer />
    </div>
  );
};
