import React, {Fragment, useEffect, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {Heading, Icon, Paragraph} from '../../ui';
import {Footer, NavigationBar, Request} from '../../sections';
import {project as projectContent} from '../../../helpers/content';
import './project.css';

export const Project = props => {
  // PROPS
  const {name, navigationBarProps = {}} = props;

  // STATE
  const [carouselSelectedItem, setCarouselSelectedItem] = useState(0);
  const [carouselSelectedItemMaximum, setCarouselSelectedItemMaximum] = useState(0);

  // CONTENT
  const content = projectContent[name];

  // USE EFFECT
  useEffect(() => {
    // CHECK IF CAROUSEL EXISTS
    if (content.bodyItems.find(item => item.type === 'carousel')) {
      const newCarouselSelectedItemMaximum =
        content.bodyItems.find(item => item.type === 'carousel').value.length - 1;
      setCarouselSelectedItemMaximum(newCarouselSelectedItemMaximum);
    }
  }, []);

  // EVENT HANDLERS
  const onCarouselControlClick = intent => {
    if (intent === 'back' && carouselSelectedItem > 0) {
      setCarouselSelectedItem(carouselSelectedItem - 1);
    }
    if (intent === 'next' && carouselSelectedItem < carouselSelectedItemMaximum) {
      setCarouselSelectedItem(carouselSelectedItem + 1);
    }
  };

  // CLASSNAMES
  let projectBodyMainCarouselControlItemBackClassName = 'project-body-main-carousel-controls-item';
  let projectBodyMainCarouselControlItemNextClassName = 'project-body-main-carousel-controls-item';

  if (carouselSelectedItem === carouselSelectedItemMaximum) {
    projectBodyMainCarouselControlItemBackClassName +=
      ' project-body-main-carousel-controls-item--isHidden';
  }
  if (carouselSelectedItem === 0) {
    projectBodyMainCarouselControlItemNextClassName +=
      ' project-body-main-carousel-controls-item--isHidden';
  }

  // RENDER
  return (
    <Fragment>
      <NavigationBar {...navigationBarProps} />
      <div className="project">
        {/* HEAD SECTION */}
        <div className="project-head">
          <img className="project-head-image" src={content.headImagePath} />
          <div className="project-head-main">
            <div className="project-head-main-heading">
              <Heading
                color="#ffffff"
                importance={2}
                lineHeight="1.5em"
                size={3}
                textAlign="center"
              >
                Bodhi at {content.title}
              </Heading>
            </div>
            <div className="project-head-main-paragraph">
              <Paragraph
                color="#ffffff"
                importance={3}
                lineHeight="1.5em"
                size={3}
                textAlign="center"
              >
                {content.subtitle}
              </Paragraph>
            </div>
          </div>
        </div>

        {/* BODY SECTION */}
        <div className="project-body">
          <div className="project-body-main">
            {content.bodyItems.map((bodyItem, index) => {
              return (
                <Fragment key={index}>
                  {/* HEADING */}
                  {bodyItem.type === 'heading' && (
                    <div className="project-body-main-heading">
                      <Heading importance={3} lineHeight="1.5em" size={4}>
                        {bodyItem.value}
                      </Heading>
                    </div>
                  )}
                  {/* PARAGRAPH */}
                  {bodyItem.type === 'paragraph' && (
                    <div className="project-body-main-paragraph">
                      <Paragraph lineHeight="1.5em" size={5}>
                        {bodyItem.value}
                      </Paragraph>
                    </div>
                  )}
                  {/* IMAGE */}
                  {bodyItem.type === 'image' && (
                    <img className="project-body-main-image" src={bodyItem.value} />
                  )}
                  {/* CAROUSEL */}
                  {bodyItem.type === 'carousel' && (
                    <div className="project-body-main-carousel">
                      <Carousel
                        className="project-body-main-carousel-main"
                        selectedItem={carouselSelectedItem}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        {bodyItem.value.map((item, index) => (
                          <div
                            className="project-body-main-carousel-main-slide"
                            style={{
                              backgroundImage: `url(${item.imagePath})`,
                            }}
                            key={index}
                          >
                            <div className="project-body-main-carousel-main-slide-heading">
                              <Heading size={3} color="#ffffff">
                                {item.title}
                              </Heading>
                            </div>
                          </div>
                        ))}
                      </Carousel>
                      <div className="project-body-main-carousel-controls">
                        <div
                          className={projectBodyMainCarouselControlItemNextClassName}
                          onClick={() => onCarouselControlClick('back')}
                        >
                          <Icon color="#ffffff" name="angle-left-solid" size="100%" />
                        </div>
                        <div
                          className={projectBodyMainCarouselControlItemBackClassName}
                          style={{
                            opacity: carouselSelectedItem === carouselSelectedItemMaximum ? 0 : 1,
                          }}
                          onClick={() => onCarouselControlClick('next')}
                        >
                          <Icon color="#ffffff" name="angle-right-solid" size="100%" />
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <Request />
      <Footer />
    </Fragment>
  );
};
