import React from 'react';
import {Link} from 'gatsby';
import {home as homeContent} from '../../../../../helpers/content';
import {useWindowSize} from '../../../../../hooks';
import {Heading, Icon, Paragraph} from '../../../../ui';
import './partners.css';

export const Partners = () => {
  // USE WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  return (
    <div className="partners">
      <div className="partners-main">
        {/* TITLE AND DESCRIPTION */}
        <div className="partners-main-heading">
          <Heading importance={2} size={3} textAlign="inherit">
            {homeContent.partners.title}
          </Heading>
        </div>
        <div className="partners-main-paragraph">
          <Paragraph lineHeight="inherit" size={4} textAlign="inherit">
            {homeContent.partners.description}
          </Paragraph>
        </div>

        {/* LIST */}
        <div className="partners-main-list">
          {homeContent.partners.items.map((item, index) => {
            // console.log('item:', item);
            let imagePath = item.logoAndTextImagePath;
            if (windowSize.width <= 800) {
              imagePath = item.logoImagePath;
            }

            return (
              <div className="partners-main-list-item" key={index}>
                <div className="partners-main-list-item-image">
                  <div
                    className="partners-main-list-item-image-main"
                    style={{
                      backgroundImage: `url("${imagePath}")`,
                    }}
                  />
                </div>
                <div className="partners-main-list-item-main">
                  <div className="partners-main-list-item-main-heading">
                    <Heading importance={4} size={4} textAlign="inherit">
                      {item.title}
                    </Heading>
                  </div>
                  <div className="partners-main-list-item-main-paragraph">
                    <Paragraph lineHeight="1.5em" size={5} textAlign="inherit">
                      {item.description}
                    </Paragraph>
                  </div>
                  <Link to={item.linkUrl} className="partners-main-list-item-main-link">
                    <div className="partners-main-list-item-main-link-text">
                      {homeContent.partners.linkText}
                    </div>
                    <div className="partners-main-list-item-main-link-icon">
                      <Icon name="long-arrow-alt-right-solid" color="inherit" size="inherit" />
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
