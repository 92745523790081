import React, {useState} from 'react';
import {Link} from 'gatsby';
import {home as homeContent} from '../../../../../helpers/content';
import {Heading, Icon, Paragraph} from '../../../../ui';
import './projects.css';

export const Projects = () => {
  // STATE
  const [selectedItem, setSelectedItem] = useState(0);

  // EVENT HANDLERS
  const onThumbnailFocus = index => {
    setSelectedItem(index);

    // DEBUGGING
    // console.log(`[debug] ${index}`)
  };

  // RENDER
  return (
    <div className="projects">
      <div className="projects-main">
        {/* TITLE */}
        <div className="projects-main-heading">
          <Heading color="inherit" size={3} textAlign="center">
            {homeContent.projects.title}
          </Heading>
        </div>

        {/* CARD, ONLY SHOWS ON LARGE DEVICES*/}
        <div className="projects-main-information">
          <div className="projects-main-information-card">
            <div
              className="projects-main-information-card-image"
              style={{
                backgroundImage: `url(${homeContent.projects.items[selectedItem].cardImagePath})`,
              }}
            />
            <div className="projects-main-information-card-main">
              <div className="projects-main-information-card-main-heading">
                <Heading size={4}>{homeContent.projects.items[selectedItem].title}</Heading>
              </div>
              <div className="projects-main-information-card-main-paragraph">
                <Paragraph lineHeight="inherit" size={5}>
                  {homeContent.projects.items[selectedItem].description}
                </Paragraph>
              </div>
              <div className="projects-main-information-card-main-link">
                <Icon name="plus-solid" size="15px" />
                <Link
                  className="projects-main-information-card-main-link-text"
                  to={homeContent.projects.items[selectedItem].url}
                >
                  {homeContent.projects.linkText}
                </Link>
              </div>
            </div>
          </div>

          {/* THUMBNAILS, ONLY SHOWS ON LARGE DEVICES*/}
          <div className="projects-main-information-thumbnails">
            {homeContent.projects.items.map((item, index) => {
              // CLASSNAME
              let className = 'projects-main-information-thumbnails-item';
              if (index === selectedItem) {
                className += ' projects-main-information-thumbnails-item--selected';
              }

              // RENDER
              return (
                <div className={className} key={index}>
                  <div
                    className="projects-main-information-thumbnails-item-image"
                    style={{
                      backgroundImage: `url(${item.thumbnailImagePath})`,
                    }}
                    tabIndex={0}
                    onFocus={() => onThumbnailFocus(index)}
                  />
                </div>
              );
            })}
          </div>

          {/* CARDS, ONLY SHOWS ON MEDIUM AND SMALL DEVICES */}
          <div className="projects-main-information-cards">
            {homeContent.projects.items.map((item, index) => (
              <div className="projects-main-information-cards-item" key={index}>
                <img
                  className="projects-main-information-cards-item-image"
                  src={item.cardImagePath}
                />
                <div className="projects-main-information-cards-item-main">
                  <div className="projects-main-information-cards-item-main-heading">
                    <Heading size={4} importance={3}>
                      {item.title}
                    </Heading>
                  </div>
                  <div className="projects-main-information-cards-item-main-paragraph">
                    <Paragraph lineHeight="1.5em" size={6}>
                      {item.description}
                    </Paragraph>
                  </div>

                  <Link className="projects-main-information-cards-item-main-link" to={item.url}>
                    <Paragraph lineHeight="1.5em" size={6}>
                      {homeContent.projects.linkText}
                    </Paragraph>
                    <div className="projects-main-information-cards-item-main-link-icon">
                      <Icon name="long-arrow-alt-right-solid" size="100%" />
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
