import {Link} from 'gatsby'
import React, {Fragment} from 'react';
import {newPage as newContent} from '../../../../../helpers/content';
import {Heading, Paragraph} from '../../../../ui';
import {useWindowSize} from '../../../../../hooks/use-window-size';
import './examples.css';

export const Examples = props => {
  // PROPS
  const {name} = props;

  // USE WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // CONTENT
  const content = newContent[name].examples;

  // RENDER
  return (
    <div className="examples">
      <div className="main">
        {/* items */}
        {content.map((item, itemIndex) => {
          return (
            <div className="grid">
              <div className="item image container">
                <div
                  className="image"
                  style={{
                    backgroundImage: `url("${item.imagePath}")`,
                  }}
                ></div>
                {item.secondaryImagePath && (
                  <>
                  {itemIndex === 0 ? <img src={item.secondaryImagePath} alt="luxury layout" className="secondary-image" /> : null}
                  {itemIndex === 0 ? (
                  <div className="typical-configurations">
                    <p className="secondary-image-text">{item.secondaryImageText}</p>
                    <div className="link-container">
                      <Link className="link" to={item.smartLink}>{item.smartLinkText}</Link>
                      <Link className="link" to={item.premiumLink}>{item.premiumLinkText}</Link>
                      <Link className="link" to={item.luxuryLink}>{item.luxuryLinkText}</Link>
                    </div>
                  </div>
                  ) : null}
                  </>
                )}
              </div>
              <div className="item">
                <div className="text container">
                  <div key={item} className="heading container">
                    <Heading importance={6} lineHeight="inherit" size={4} textAlign="inherit">
                      {item.value}
                    </Heading>
                  </div>

                  {/* first bullet point */}
                  {item?.items?.map((item, itemIndex) => {
                    return (
                      <Fragment key={itemIndex}>
                        <div className="space" />
                        <div className="bullet-point container">
                          <Paragraph lineHeight="inherit" textAlign="inherit" size={7}>
                            {item.value}
                          </Paragraph>
                        </div>

                        {/* second bullet point */}
                        {item?.items?.map((item, itemIndex) => {
                          return (
                            <div className="sub bullet-point container">
                              <Paragraph
                                key={itemIndex}
                                lineHeight="inherit"
                                textAlign="inherit"
                                size="7"
                              >
                                {item.value}
                              </Paragraph>
                            </div>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
