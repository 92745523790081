import React from 'react';
import {newPage as newContent} from '../../../../../helpers/content';
import {Heading, Paragraph} from '../../../../ui';
import {useWindowSize} from '../../../../../hooks/use-window-size';
import './intro.css';

export const Intro = props => {
  // PROPS
  const {name} = props;

  // USE WINDOW SIZE HOOK
  const windowSize = useWindowSize();

  // CONTENT
  const content = newContent[name].intro;

  // RENDER
  return (
    <div className="intro">
      <div
        className={`main`}
        style={{
          backgroundImage: `url("${content.backgroundImagePath}")`,
        }}
      >
        <div
          className="mobile overlay"
          style={{
            backgroundColor: content.mobileBackgroundOverlayColor,
          }}
        />
        <div className="text">
          <div className={`heading container`}>
            <Heading
              color={content.textColor}
              importance={6}
              lineHeight="inherit"
              size={1}
              textAlign="inherit"
            >
              {content.title}
            </Heading>
          </div>
          <div className={`paragraph container`}>
            <Paragraph color={content.textColor} lineHeight="inherit" textAlign="inherit" size={4}>
              {content.description}
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};
