import React from 'react';
import {Fragment} from 'react';
import {partner as partnerContent} from '../../../../../helpers/content';
import {Heading, Paragraph} from '../../../../ui';
import './examples.css';

export const Examples = props => {
  // PROPS
  const {name} = props;

  console.log(name);
  // CONTENT
  const content = partnerContent[name].examples;

  // RENDER
  return (
    <div className="examples">
      <div className="examples-main">
        {/* ITEMS,  ONLY SHOWS ON LARGE DEVICES*/}
        {content.items.map((item, index) => (
          <div className="examples-main-item" key={index}>
            <div className="examples-main-item-heading">
              <Heading importance={2} lineHeight="1.5em" size={3} textAlign="center">
                {item.primaryTitle}
              </Heading>
            </div>
            <div className="examples-main-item-main">
              <div className="examples-main-item-main-heading">
                <Heading
                  color={'#ffffff'}
                  importance={2}
                  lineHeight="1.5em"
                  size={4}
                  textAlign="inherit"
                >
                  {item.secondaryTitle}
                </Heading>
              </div>
              {item.description.map((descriptionItem, index) => (
                <div className="examples-main-item-main-paragraph">
                  <Paragraph
                    color="#ffffff"
                    key={'index'}
                    size={5}
                    lineHeight="1.5em"
                    textAlign="inherit"
                  >
                    {descriptionItem}
                  </Paragraph>
                </div>
              ))}
              <img
                alt={''}
                className="examples-main-item-main-primary-image"
                src={item.primaryImagePath}
              />
              <img
                alt={''}
                className="examples-main-item-main-secondary-image"
                src={item.secondaryImagePath}
              />
            </div>
          </div>
        ))}

        {/* CARDS, ONLY SHOWS ON MEDIUM AND SMALL DEVICES */}
        <div className="examples-main-cards">
          {content.items.map((item, index) => (
            <div className="examples-main-cards-item" key={index}>
              <img className="examples-main-cards-item-image" src={item.primaryImagePath} />
              <div className="examples-main-cards-item-main">
                <div className="examples-main-cards-item-main-heading">
                  <Heading importance={3} lineHeight="1.5em" size={4}>
                    {item.primaryTitle}
                  </Heading>
                  <Heading importance={4} lineHeight="1.5em" size={5}>
                    {item.secondaryTitle}
                  </Heading>
                </div>
                <div className="examples-main-cards-item-main-paragraph">
                  {item.description.map((descriptionItem, index) => (
                    <div className="examples-main-item-main-paragraph">
                      <Paragraph key={'index'} size={6} lineHeight="1.5em">
                        {descriptionItem}
                      </Paragraph>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
